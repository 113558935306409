var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"about"},[_vm._m(0),_c('br'),_vm._m(1),_c('div',{staticClass:"profile-card"},[_c('img',{staticClass:"profile-card-img",attrs:{"src":"/img/Nia-profile.png"}}),_c('div',{staticClass:"profile-card-content"},[_c('table',{staticClass:"borderless-table"},[_c('tbody',[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('tr',[_vm._m(7),_c('td',[_c('div',{staticClass:"sns-buttons"},[_c('sns-button',{attrs:{"icon-name":"twitter","link":"https://twitter.com/nia_tn1012","button-name":"Twitter","bg-color":"#00acee"}}),_c('sns-button',{attrs:{"icon-name":"facebook","link":"https://www.facebook.com/niatn1012","button-name":"Facebook","bg-color":"#3b5998"}}),_c('sns-button',{attrs:{"icon-name":"github","link":"https://github.com/Nia-TN1012","button-name":"GitHub","bg-color":"#24292e"}}),_c('sns-button',{attrs:{"icon-name":"pen-fancy","icon-type":"s","link":"https://chronoir.net/","button-name":"Blog","bg-color":"#2464ff"}}),_c('sns-button',{attrs:{"is-show-icon":"false","link":"https://qiita.com/nia_tn1012","button-name":"Qiita","bg-color":"#55c500"}}),_c('sns-button',{attrs:{"icon-name":"github","link":"https://hub.docker.com/u/niatn1012/","button-name":"Docker Hub","bg-color":"#099cec"}}),_c('sns-button',{attrs:{"is-show-icon":"false","link":"https://www.nuget.org/profiles/nia_tn1012","button-name":"NuGet Gallery","bg-color":"#004880"}})],1)])])])])])]),_c('br'),_c('br'),_vm._m(8),_c('br')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h2',[_c('i',{staticClass:"fas fa-address-card"}),_vm._v(" About")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h3',[_c('span',[_vm._v("My Profile")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_c('i',{staticClass:"fas fa-signature"}),_vm._v(" Name")]),_c('td',[_vm._v("中曽根 智文（Tomofumi Nakasone）")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_c('i',{staticClass:"fas fa-signature"}),_vm._v(" (a.k.a.)")]),_c('td',[_vm._v("智中 ニア（Nia Tomonaka）")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_c('i',{staticClass:"fas fa-birthday-cake"}),_vm._v(" Birthday")]),_c('td',[_vm._v("1992/10/12")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_c('i',{staticClass:"fas fa-landmark"}),_vm._v(" Speciality")]),_c('td',[_vm._v(" フロントエンドやサーバーサイドでの開発、データベースの設計・チューニング、インフラ（AWSなどのクラウドを利用）の構成・運用 / ブロックチェーン技術周りの研究・開発 ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_c('i',{staticClass:"far fa-play-circle"}),_vm._v(" Hobby")]),_c('td',[_vm._v("写真撮影、音ゲー")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('i',{staticClass:"fas fa-link"}),_vm._v(" SNS / Links")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"profile-balloon"},[_c('img',{staticClass:"profile-balloon-chara",attrs:{"src":"/img/Nia-sd-profile.png"}}),_c('div',{staticClass:"profile-balloon-container"},[_c('div',{staticClass:"profile-balloon-content"},[_c('p',[_vm._v("こんにちはー！ 私は生まれも育ちも湘南のエンジニアです！ ")]),_c('p',[_vm._v(" 新卒ではITのベンチャー企業に入社し、学生時代からC#やPHPを使ったプログラミングやアプリ開発をした経験を活かしつつ、 業務ではゲームのサーバーサイドやフロントエンドでの運用・開発に携わりました。"),_c('br'),_vm._v(" 運用をしているうちに、データベースやインフラも含めてゲームのシステム全体を見れるようにしたいと思い、 2年目からは、データベースの設計・チューニングやインフラの運用にも携わり、キャリアとしてのフルスタックエンジニアを目指していました。 ")]),_c('p',[_vm._v(" 最近ではブロックチェーンに興味を持ったのと、1からシステムの開発に携わりたく思い、ブロックチェーンを使用したシステムの開発会社に転職しました。"),_c('br'),_vm._v(" フルスタック＋ブロックチェーンエンジニアとして、研究や開発に精進していきます。"),_c('br')]),_c('p',[_vm._v(" 趣味は写真撮影と音ゲーです。たまに非日常を求めて一人旅しています。"),_c('br'),_vm._v(" 好きなものは、コーヒー、紅茶、シラス丼（湘南といえば、江ノ島のシラス丼！） ")]),_c('p',[_vm._v(" プロフィール画像のイラストは自分で描きました。TwitterなどのSNSのプロフィール画像で使うためです。 ")]),_c('p',[_vm._v("よろしくお願いします！")])])])])
}]

export { render, staticRenderFns }