
import { Component, Prop, Vue } from 'vue-property-decorator';
import SnsButton from '@/components/SnsButton.vue';

@Component({
    components: {
        SnsButton
    }
})
export default class About extends Vue {
}
