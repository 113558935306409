
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SnsButton extends Vue {
    @Prop()
    private buttonName!: string
    @Prop()
    private link!: string
    @Prop( { default: true } )
    private isShowIcon!: boolean
    @Prop( { default: "b" } )
    private iconType!: string
    @Prop()
    private iconName!: string
    @Prop()
    private bgColor!: string

    get iconClass() {
        return [
            "fa" + this.iconType,
            "fa-" + this.iconName
        ]
    }
}
